// Import mixins
@import 'mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-table-column-has-sorters {
    &:hover {
      background: $dark-gray-5 !important;
    }
  }
}
