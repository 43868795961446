// Import mixins
@import 'mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-skeleton {
    opacity: 0.1;
  }
}
