// Colors
$white: #fff;
$black: #161537;
$blue: var(--kit-color-primary);
$blue-light: #3d6ee7;
$blue-dark: #103daf;
$gray-1: #f2f4f8;
$gray-2: #e4e9f0;
$gray-3: #dde2ec;
$gray-4: #c3bedc;
$gray-5: #aca6cc;
$gray-6: #786fa4;
$yellow: #ff0;
$orange: #f2a654;
$red: #f00;
$pink: #fd3995;
$purple: #652eff;
$green: #41b883;

$text: $gray-6;
$border: $gray-2;

// Accent colors
$default: $gray-4;
$primary: $blue;
$secondary: $gray-5;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #2a274d;
$dark-gray-5: #161537;
$dark-gray-6: #100f28;

// Font Family
$base-font-family: var(--kit-font-family);

// Font Size
$base-font-size: 15 !default;
.ant-tabs-nav .ant-tabs-nav:active:focus,.ant-tabs-nav .ant-tabs-tab-active:hover, 
.ant-tabs-nav .ant-tabs-tab-active:active,.ant-tabs-nav .ant-tabs-tab-active:focus,.ant-tabs-nav .ant-tabs-tab-active:hover:active, 
.ant-tabs-nav .ant-tabs-tab-active:hover:focus,.ant-tabs-nav .ant-tabs-tab-active:active:focus,
.ant-tabs-nav .ant-tabs-tab-active{
    color:var(--kit-color-primary) !important;
}
.ant-tabs-nav .ant-tabs-tab:active,.ant-tabs-nav .ant-tabs-tab:hover,.ant-tabs-nav .ant-tabs-tab:active,
.ant-tabs-nav .ant-tabs-tab:active:focus,.ant-tabs-nav .ant-tabs-tab:active:hover {
    color:var(--kit-color-primary) !important;
}
[data-kit-theme='dark'] .ant-tabs-nav{
    color:#aeaee0 !important;
}

[data-kit-theme='dark'] .ant-table-row{
    color: #aeaee0 !important;
}
[data-kit-theme='dark'] .card {
    background: #100f28 !important;
    border-color: #2a274d !important;
}
[data-kit-theme='dark'] .style_logoName__3-mJT {
    color: #aeaee0 !important;
}
[data-kit-theme='dark'] .air__layout__headerGray {
    background: #12112c !important;
}
.style_amountValue__2yzm5 {
    color:#c3bedc !important;
}
.ant-tabs-ink-bar{
    background-color:var(--kit-color-primary) !important;
}
.ant-pagination-item-active{
    border-color: var(--kit-color-primary) !important;
}

.ant-pagination-item-active a{
    color:var(--kit-color-primary) !important;
}

::selection {
    color: #fff;
    background: var(--kit-color-primary) !important;
}

.btn:hover, .btn:active, .btn:focus, .btn:hover:active, .btn:hover:focus, .btn:active:focus,
.btn.btn-primary:hover, .btn.btn-primary:active,
.btn.btn-primary:focus, .btn.btn-primary:hover:active, 
.btn.btn-primary:hover:focus, .btn.btn-primary:active:focus {
    color: #fff !important;
    background-color: var(--kit-color-primary) !important;
    border-color: var(--kit-color-primary) !important;
}

.ant-btn.ant-btn-primary:hover, .ant-btn.ant-btn-primary:active,
.ant-btn.ant-btn-primary:focus, .ant-btn.ant-btn-primary:hover:active, 
.ant-btn.ant-btn-primary:hover:focus, .ant-btn.ant-btn-primary:active:focus {
    color: #fff !important;
    background-color: var(--kit-color-primary) !important;
    border-color: var(--kit-color-primary) !important;
}
.ant-switch-checked {
    background-color: var(--kit-color-primary) !important;
}

.ant-btn:hover, .ant-btn:active, .ant-btn:focus {
    border-color: var(--kit-color-primary) !important;
    color: var(--kit-color-primary) !important;
}