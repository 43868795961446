// Import mixins from "core" module
@import 'mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* JUMBOTRON */

// dark theme
[data-kit-theme='dark'] {
  .jumbotron {
    background: $dark-gray-4;
  }
}
