// Import mixins
@import 'mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-tag {
    border-color: transparent;
  }
}
